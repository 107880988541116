(<template>
  <full-calendar v-if="events && showCalendar"
                 :key="calendarRenderId"
                 :active-date="activeDate"
                 :selectable="selectable"
                 :view="view"
                 :events="eventsList"
                 :column-header="columnHeader"
                 class="full-calendar"
                 @nav-date-click="navDateClick"
                 @event-click="eventClick"
                 @catch-calendar-instance="catchCalendar"
                 @period-selected="selectPeriod"
                 @event-mount="eventMount" />
</template>)

<script>
  import Vue from 'vue';
  import {mapGetters} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      events: {
        type: Object,
        default() {
          return {};
        }
      },
      view: {
        type: String,
        default: ''
      },
      activeDate: {
        type: [String, Object],
        default: ''
      },
      columnHeader: {
        type: [Boolean, undefined],
        default: undefined
      }
    },
    data() {
      return {
        calendarRenderId: '',
        showCalendar: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      selectable() {
        return (this.$route.query.view === 'week' || this.$route.query.view === 'day') && this.userIsInterpreter;
      },
      mainProgressActive() {
        return this.$store.state.GlobalStore.mainProgressActive;
      },
      eventsList() {
        const res = this.events;
        let data = [];
        if (res && res.jobs) {
          data = data.concat(res.jobs);
        }
        if (res && res.events) {
          data = data.concat(res.events);
        }
        if (res && res.travelTimes) {
          data = data.concat(res.travelTimes[0], res.travelTimes[1]);
        }
        return data.map((event) => {
          const langFrom = this.$getLangName(event.langFromId) || '';
          const langTo = this.$getLangName(event.langToId) || '';
          const customer = event.customer || '';
          const eventId = helpers.getJobInfo.jobId(event);
          const title = langFrom || langTo || customer ? `#${eventId} ${langFrom} - ${langTo} - ${customer}` : '';
          const travelTimeTitle = event.eventType == 'travelToTime' || event.eventType == 'travelFromTime'
            ? this.$gettext('Travel time') : '';
          const privateEventTitle = event.eventType == '1' ? this.getPrivateEventTitle(event) : '';
          const standByEventTitle = event.eventType == '3' ? this.$gettext('StandBy interpreter') : '';
          return {
            id: event.id,
            data: event,
            start: event.startTime,
            end: event.finishTime,
            title: title || travelTimeTitle || privateEventTitle || standByEventTitle || '',
            eventType: event.eventType,
            status: event.status,
            classNames: [
              event.eventType || '',
              event.status || '',
              event.sessionType || '',
              event.eventType == '1' ? 'block_time' : event.eventType == '3' ? 'stand-by' : `event-with-id-${event.id}`,
              event.allDay ? 'all_day' : ''
            ]
          };
        });
      }
    },
    watch: {
      $route() {
        setTimeout(() => {
          this.renderCalendar();
        }, 0);
      },
      events() {
        setTimeout(() => {
          this.renderCalendar();
        }, 0);
      },
      mainProgressActive() {
        setTimeout(() => {
          this.renderCalendar();
        }, 0);
      }
    },
    methods: {
      renderCalendar() {
        this.$emit('startprogress');
        setTimeout(() => {
          if (this.events) {
            Vue.component('full-calendar', () => import('@/components/calendars/FullCalendarCore'));
            this.calendarRenderId = Math.random();
            this.showCalendar = true;
            this.$emit('stopprogress');
            this.addMouseUpEventListeners();
          }
        }, 0);
      },
      eventMount(arg) {
        const statusProactive = arg.event.extendedProps.status === 'proactive' ? 'Proactive' : '';
        if (arg && statusProactive) {
          const timeElement = arg.el.querySelector('.fc-event-time');
          timeElement.innerHTML += ` <span class="proactive-label">${statusProactive}</span>`;
        }
      },
      navDateClick(date) {
        this.$router.push({
          name: this.$route.name,
          query: {
            view: 'day',
            date: this.$moment(date).format('YYYY-MM-DD')
          }
        });
      },
      eventClick(info) {
        const event = info.event || {};
        if (event.extendedProps?.eventType === 1) {
          this.$router.push({name: 'ServerPreferencesAvailability'});
          return;
        }
        if (event.extendedProps?.eventType === 3) {
          this.$router.push({name: 'ServerHome'});
          return;
        }
        if (event.id && event.extendedProps?.data && event.extendedProps.data.status) {
          this.$store.commit('ModalStore/setModal', {
            component: 'job-preview-modal',
            classes: ['job-preview-modal'],
            width: 375,
            data: {
              data: event.extendedProps.data
            }
          });
        }
      },
      selectPeriod(data) {
        this.$emit('periodselected', data);
      },
      catchCalendar(inst) {
        this.$emit('catchcalendarinstance', inst);
      },
      getPrivateEventTitle(event) {
        let template = '';
        if (event.allDay) {
          template = this.$gettext('All day \n Time off: %{title}');
        } else {
          template = this.$gettext('Time off: %{title}');
        }
        return this.$gettextInterpolate(template, {title: event.title || ''});
      },
      addMouseUpEventListeners() {
        const listener = (eventInfo) => {
          const handler = (event) => {
            event.preventDefault();
            const eventId = eventInfo.id;
            if (event.button === 1) {
              const routeData = this.$router.resolve({
                name: this.userIsInterpreter ? 'ServerOneAssignment' : 'BuyerOneAssignment',
                params: {id: eventId}
              });
              window.open(routeData.href, '_blank');
            }
          };
          return handler;
        };

        setTimeout(() => {
          const eventsList = this.eventsList || [];
          eventsList.forEach((eventInfo) => {
            const element = document.querySelector(`.event-with-id-${eventInfo.id}`);
            if (element) {
              element.onmouseup = listener(eventInfo);
            }
          });
        }, 500);
      }
    },
    mounted() {
      this.renderCalendar();
    }
  };
</script>

<style>
  /* .fc-dayGridMonth-view */

  /* .fc-timeGridDay-view */

  /* .fc-timeGridWeek-view */
  .full-calendar .fc-day-header {
    padding: 6px 0;
    border: 1px solid transparent;
  }

  .full-calendar.fc-timeGridDay-view .fc-col-header td,
  .full-calendar .fc-timeGridWeek-view .fc-col-header th {
    border: 1px solid transparent;
  }

  .full-calendar .fc-timeGridDay-view table.fc-scrollgrid,
  .full-calendar .fc-timeGridWeek-view table.fc-scrollgrid,
  .full-calendar .fc-dayGridMonth-view table.fc-scrollgrid {
    border: 1px solid transparent;
  }

  .full-calendar .fc-timeGridDay-view .fc-scrollgrid .fc-scrollgrid-section-header .fc-scroller,
  .full-calendar .fc-timeGridWeek-view .fc-scrollgrid .fc-scrollgrid-section-header .fc-scroller {
    overflow: hidden !important;
  }

  .full-calendar .fc-timeGridWeek-view .fc-scrollgrid .fc-scrollgrid-section-header .fc-scroller {
    border-bottom: 1px solid #ddd;
  }

  .full-calendar .fc-timeGridDay-view .fc-scrollgrid .fc-scrollgrid-section-body .fc-scroller {
    border-top: 1px solid #ddd;
  }

  .full-calendar .fc-dayGridMonth-view table.fc-scrollgrid td {
    border-left: 1px solid #ddd;
  }

  .full-calendar td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
    border: 1px solid transparent;
  }

  .full-calendar .fc-scrollgrid-section.fc-scrollgrid-section-header th {
    border-right: 1px solid transparent;
  }

  .full-calendar .fc-daygrid-more-link:focus {
    outline: auto;
  }

  .full-calendar .fc-dayGridMonth-view .fc-body .fc-row {
    min-height: 7em;
  }

  .full-calendar .fc-head .fc-widget-header {
    border-right: 1px solid transparent;
    font-size: 12px;
  }

  .full-calendar .fc-timeGridDay-view .fc-widget-content,
  .full-calendar .fc-timeGridWeek-view .fc-widget-content {
    border-left: 1px solid transparent;
  }

  .full-calendar .fc-timeGridDay-view .fc-widget-content {
    border-right: 1px solid transparent;
  }

  .full-calendar .fc-time {
    border-top: 1px solid transparent;
    font-size: 10px;
  }

  .full-calendar .fc-widget-content.fc-today {
    background: #e9ebef;
  }

  .full-calendar .fc-timeGridDay-view .fc-widget-content.fc-today {
    background: none;
  }

  .full-calendar td.fc-disabled-day {
    background: #eaeaea;
  }

  .full-calendar td.fc-daygrid-day {
    height: 10em;
  }

  .full-calendar .fc-event {
    border: 1px solid transparent;
    background-color: #fff;
    cursor: pointer;
  }

  .full-calendar .fc-v-event .fc-event-main {
    color: #000;
    line-height: 1.4em;
  }

  .full-calendar .fc-event:focus {
    box-shadow: 0 0 2px 1px #3af;
  }

  .full-calendar .fc-event:hover {
    box-shadow: inset 3px 0 0 0 rgba(0, 34, 102, 0.2);
  }

  .full-calendar .fc-event:active {
    box-shadow: none;
  }

  .full-calendar .fc-timegrid-event-harness:hover {
    z-index: 1000 !important;
  }

  .full-calendar .fc-event.needsFeedback,
  .full-calendar .fc-event.in_progress,
  .full-calendar .fc-event.finished,
  .full-calendar .fc-event.accepted {
    background-color: #ccf6e4;
  }

  .full-calendar .fc-event.needsFeedback .fc-event-main,
  .full-calendar .fc-event.in_progress .fc-event-main,
  .full-calendar .fc-event.finished .fc-event-main,
  .full-calendar .fc-event.accepted .fc-event-main {
    color: #000;
  }

  .full-calendar .fc-event.travelToTime,
  .full-calendar .fc-event.travelFromTime {
    background-color: #e2f0d9;
  }

  .full-calendar .fc-event.block_time {
    background-color: #fd6;
  }

  .full-calendar .fc-event.stand-by {
    background-color: #69c3bd;
  }

  .full-calendar .fc-event.not_clickable {
    cursor: default;
  }

  .full-calendar .fc-event.not_clickable:hover {
    z-index: 1 !important;
  }

  .full-calendar .fc-event.all_day .fc-time {
    display: none;
  }

  .full-calendar .fc-event.cancelled,
  .full-calendar .fc-event.rejected,
  .full-calendar .fc-event.withdrawn {
    background-color: #fff;
    color: #ff3369;
  }

  .full-calendar .fc-event.cancelled .fc-event-main,
  .full-calendar .fc-event.rejected .fc-event-main,
  .full-calendar .fc-event.withdrawn .fc-event-main {
    color: #ff3369;
  }

  .full-calendar .fc-daygrid-event-dot {
    display: none;
  }

  .full-calendar .fc-daygrid-event .fc-event-time {
    font-weight: bold;
  }

  .full-calendar .fc-daygrid-event .fc-event-title {
    font-weight: normal;
  }

  .full-calendar .fc-timegrid .fc-timegrid-slots td {
    height: 2.3em;
  }

  .full-calendar td.fc-day-today {
    background: #e9ebef !important;
  }

  .full-calendar .fc-timeGridDay-view .fc-day-today {
    background: none !important;
  }

  .full-calendar .fc-timeGridDay-view .fc-event::after {
    content: '';
    position: absolute;
    top: 13px;
    right: 10px;
    width: 20px;
    height: 13px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .full-calendar .fc-timeGridDay-view .fc-event.video::after {
    background-image: url(~@assets/imgs/assignment_types/calendar/video_icon_calendar.svg);
    background-size: 18px auto;
  }

  .full-calendar .fc-timeGridDay-view .fc-event.phone::after {
    background-image: url(~@assets/imgs/assignment_types/calendar/phone_icon_calendar.svg);
    background-size: 13px auto;
  }

  .full-calendar .fc-timeGridDay-view .fc-event.videoroom::after {
    background-image: url(~@assets/imgs/assignment_types/calendar/video_room_icon_calendar.svg);
    background-size: 13px auto;
  }

  .full-calendar .fc-timeGridDay-view .fc-event.in_person::after {
    background-image: url(~@assets/imgs/assignment_types/calendar/in_person_icon_calendar.svg);
    background-size: 10px auto;
  }

  /* Cancelled events */
  .full-calendar .fc-timeGridDay-view .fc-event.cancelled.video::after {
    background-image: url(~@assets/imgs/assignment_types/calendar/video_icon_calendar_cancelled.svg);
  }

  .full-calendar .fc-timeGridDay-view .fc-event.cancelled.phone::after {
    background-image: url(~@assets/imgs/assignment_types/calendar/phone_icon_calendar_cancelled.svg);
  }

  .full-calendar .fc-timeGridDay-view .fc-event.cancelled.videoroom::after {
    background-image: url(~@assets/imgs/assignment_types/calendar/video_room_icon_calendar_cancelled.svg);
  }

  .full-calendar .fc-timeGridDay-view .fc-event.cancelled.in_person::after {
    background-image: url(~@assets/imgs/assignment_types/calendar/in_person_icon_calendar_cancelled.svg);
  }

  /* Cancelled events end */
  .proactive-label {
    color: #104a81;
    font-weight: 700;
    font-size: 10px;
  }
</style>
